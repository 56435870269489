import styles from "./BlobShapes.module.css";

export default function BlobShapes() {
  return (
    <div className={styles.BlobCircles}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}
