import styles from "./BlobWaves.module.css";

export default function BlobWaves() {
  return (
    <div className={styles.Blob}>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1920"
        height="948"
        preserveAspectRatio="xMidYMid"
        viewBox="0 0 1920 948"
      >
        <g transform="translate(960,474) scale(1,1) translate(-960,-474)">
          <linearGradient
            id="lg-0.7325542001666538"
            x1="0"
            x2="1"
            y1="0"
            y2="0"
          >
            <stop stop-color="#ff00ff" offset="0"></stop>
            <stop stop-color="#00ffff" offset="1"></stop>
          </linearGradient>
          <path d="" fill="url(#lg-0.7325542001666538)" opacity="0.4">
            <animate
              attributeName="d"
              dur="10s"
              repeatCount="indefinite"
              keyTimes="0;0.333;0.667;1"
              calcMode="spline"
              keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1"
              begin="0s"
              values="M0 0L 0 787.3701476992007Q 192 705.0510317945221  384 686.7450819316188T 768 662.598128599045T 1152 580.847825481879T 1536 521.4217981456168T 1920 316.0479613655168L 1920 0 Z;M0 0L 0 825.9217702483315Q 192 880.3945730162274  384 843.6285625107106T 768 680.1950408927094T 1152 605.3883670223893T 1536 575.0336428779581T 1920 475.2779085986308L 1920 0 Z;M0 0L 0 872.2587550216247Q 192 873.6782250761019  384 846.937766997329T 768 725.8301728901988T 1152 594.7286171174462T 1536 401.54379361587377T 1920 445.00860496415277L 1920 0 Z;M0 0L 0 787.3701476992007Q 192 705.0510317945221  384 686.7450819316188T 768 662.598128599045T 1152 580.847825481879T 1536 521.4217981456168T 1920 316.0479613655168L 1920 0 Z"
            ></animate>
          </path>
          <path d="" fill="url(#lg-0.7325542001666538)" opacity="0.4">
            <animate
              attributeName="d"
              dur="10s"
              repeatCount="indefinite"
              keyTimes="0;0.333;0.667;1"
              calcMode="spline"
              keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1"
              begin="-2s"
              values="M0 0L 0 813.6072036431004Q 192 757.6507711545702  384 710.8047040127813T 768 752.337516663337T 1152 655.3836979587663T 1536 544.3516399344262T 1920 418.46643158923746L 1920 0 Z;M0 0L 0 919.5951612225372Q 192 796.274139751461  384 777.6949869125077T 768 685.4833822562509T 1152 636.1433875307919T 1536 525.0511187340533T 1920 387.9571377970785L 1920 0 Z;M0 0L 0 808.9286816120937Q 192 875.4030914761058  384 859.7949726509964T 768 772.9982511606504T 1152 531.0450641313787T 1536 567.9278226832835T 1920 498.3281866869866L 1920 0 Z;M0 0L 0 813.6072036431004Q 192 757.6507711545702  384 710.8047040127813T 768 752.337516663337T 1152 655.3836979587663T 1536 544.3516399344262T 1920 418.46643158923746L 1920 0 Z"
            ></animate>
          </path>
          <path d="" fill="url(#lg-0.7325542001666538)" opacity="0.4">
            <animate
              attributeName="d"
              dur="10s"
              repeatCount="indefinite"
              keyTimes="0;0.333;0.667;1"
              calcMode="spline"
              keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1"
              begin="-4s"
              values="M0 0L 0 828.3002955782301Q 192 803.1419170506128  384 763.5490520555916T 768 752.5241538470416T 1152 677.2318706298058T 1536 479.62650782277706T 1920 513.2065381401196L 1920 0 Z;M0 0L 0 896.7332576254975Q 192 857.2263318489534  384 831.9614753931947T 768 604.5175881960795T 1152 537.2028772501351T 1536 471.3971959601329T 1920 427.2389569090482L 1920 0 Z;M0 0L 0 783.2533965526349Q 192 727.5752312984803  384 709.6992105580059T 768 759.1756142700617T 1152 607.7188572099031T 1536 600.7783865258327T 1920 423.9409620011124L 1920 0 Z;M0 0L 0 828.3002955782301Q 192 803.1419170506128  384 763.5490520555916T 768 752.5241538470416T 1152 677.2318706298058T 1536 479.62650782277706T 1920 513.2065381401196L 1920 0 Z"
            ></animate>
          </path>
          <path d="" fill="url(#lg-0.7325542001666538)" opacity="0.4">
            <animate
              attributeName="d"
              dur="10s"
              repeatCount="indefinite"
              keyTimes="0;0.333;0.667;1"
              calcMode="spline"
              keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1"
              begin="-6s"
              values="M0 0L 0 841.3711081828559Q 192 764.300972278152  384 741.9526551052301T 768 731.8254931400268T 1152 665.5332995135943T 1536 419.2700337895302T 1920 344.943022785164L 1920 0 Z;M0 0L 0 943.584274889693Q 192 850.0175552227997  384 803.8692941755802T 768 719.6760405393056T 1152 531.106915830137T 1536 410.6564863793681T 1920 464.4586545941672L 1920 0 Z;M0 0L 0 798.7630777951098Q 192 861.3364376402415  384 833.611593410611T 768 754.8695867851369T 1152 685.8926184939335T 1536 556.9040337062288T 1920 493.9862298765861L 1920 0 Z;M0 0L 0 841.3711081828559Q 192 764.300972278152  384 741.9526551052301T 768 731.8254931400268T 1152 665.5332995135943T 1536 419.2700337895302T 1920 344.943022785164L 1920 0 Z"
            ></animate>
          </path>
          <path d="" fill="url(#lg-0.7325542001666538)" opacity="0.4">
            <animate
              attributeName="d"
              dur="10s"
              repeatCount="indefinite"
              keyTimes="0;0.333;0.667;1"
              calcMode="spline"
              keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1"
              begin="-8s"
              values="M0 0L 0 912.2357209575805Q 192 715.8743522169499  384 695.8214779361628T 768 735.0483702202882T 1152 684.0724319257708T 1536 591.4348543537861T 1920 523.5534957569377L 1920 0 Z;M0 0L 0 765.7934373076423Q 192 877.8377387615506  384 857.5923819866343T 768 781.249043013315T 1152 537.7201523591913T 1536 578.5817832193798T 1920 461.947093405256L 1920 0 Z;M0 0L 0 867.7755720189987Q 192 861.8233059504549  384 834.6139418495943T 768 614.0311256411392T 1152 509.6777129533858T 1536 466.1225311409925T 1920 401.45985057746907L 1920 0 Z;M0 0L 0 912.2357209575805Q 192 715.8743522169499  384 695.8214779361628T 768 735.0483702202882T 1152 684.0724319257708T 1536 591.4348543537861T 1920 523.5534957569377L 1920 0 Z"
            ></animate>
          </path>
        </g>
      </svg> */}
    </div>
  );
}
