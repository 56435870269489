
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/parties",
      function () {
        return require("private-next-pages/parties/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/parties"])
      });
    }
  