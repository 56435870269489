import { type SVGProps } from "react";

import { useColorModeValue } from "@chakra-ui/react";

const SearchPartiesArt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 625.92 557.58"
    width="1em"
    height="1em"
    {...props}
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1={139.23}
        y1={451.92}
        x2={257.32}
        y2={309.37}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#e4208b" />
        <stop offset={1} stopColor="#d91cd1" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1={145.15}
        y1={520.2}
        x2={249.2}
        y2={398.98}
        xlinkHref="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-3"
        x1={460.07}
        y1={389.19}
        x2={615.46}
        y2={601.45}
        xlinkHref="#linear-gradient"
      />
      <style>{`.cls-2{fill:${useColorModeValue("#dedede", "#1a232f")}}`}</style>
    </defs>
    <g
      style={{
        isolation: "isolate",
      }}
    >
      <g id="Layer_1" data-name="Layer 1">
        <path
          className="cls-2"
          d="M219.41 534.41C60.16 523.31-8.75 345.91.88 207.27c22.82-328.41 401-203 558.75-56.64 42.53 39.47 73.89 96.79 64.68 154.1-23.51 146.09-278.62 238.48-404.9 229.68Z"
        />
        <rect
          x={123.36}
          y={197.54}
          width={365.58}
          height={337.45}
          rx={21.56}
          style={{
            fill: useColorModeValue("#e6c2dc", "#323d4c"),
          }}
        />
        <rect
          className="cls-2"
          x={232.16}
          y={372.44}
          width={222.02}
          height={7.57}
          rx={2.52}
        />
        <rect
          className="cls-2"
          x={232.16}
          y={392.9}
          width={113.16}
          height={7.31}
          rx={1.77}
        />
        <rect
          x={174.72}
          y={368.2}
          width={37.04}
          height={37.04}
          rx={5.53}
          style={{
            fill: "url(#linear-gradient)",
          }}
        />
        <rect
          className="cls-2"
          x={232.16}
          y={448.76}
          width={222.02}
          height={7.57}
          rx={2.52}
        />
        <rect
          className="cls-2"
          x={232.16}
          y={469.22}
          width={113.16}
          height={7.31}
          rx={1.77}
        />
        <rect
          x={174.72}
          y={445.65}
          width={37.04}
          height={37.04}
          rx={5.53}
          style={{
            fill: "url(#linear-gradient-2)",
          }}
        />
        <path
          d="M279.38 255.21q.28-13.56 9.47-21.77t25.39-8.22q15.28 0 24.68 7.71a24.74 24.74 0 0 1 9.4 20 25.06 25.06 0 0 1-3.81 13.88A33.08 33.08 0 0 1 333 277.3q-7.17 4.15-9.68 7.46a13.86 13.86 0 0 0-2.51 8.61v3.8h-19l-.14-4.16q-.72-8.33 2.33-13.7t10.58-9.83q7-4.16 9.5-7.46a13.33 13.33 0 0 0 2.55-8.39 10.77 10.77 0 0 0-3.77-8.47 14.4 14.4 0 0 0-9.86-3.3 13.83 13.83 0 0 0-10 3.63 14 14 0 0 0-4.16 9.72Zm40.46 73a11.69 11.69 0 0 1-8.32 3.26 11.55 11.55 0 0 1-8.29-3.26 11.09 11.09 0 0 1 0-16 11.51 11.51 0 0 1 8.29-3.26 11.65 11.65 0 0 1 8.32 3.26 11 11 0 0 1 0 16Z"
          style={{
            fill: "#fff",
          }}
        />
        <circle
          cx={311.55}
          cy={263.57}
          r={121.24}
          transform="rotate(-45 311.554 263.57)"
          style={{
            opacity: 0.4,
            mixBlendMode: "hard-light",
            fill: "#fff",
          }}
        />
        <path
          d="m491.06 426.59-76-77c43.89-52.18 41.62-130.42-7.21-179.9-51.79-52.47-136.61-53-189.08-1.25s-53 136.6-1.25 189.07c48.83 49.48 127 52.8 179.79 9.59l76 77ZM235.25 340a108.77 108.77 0 1 1 153.83 1 108.9 108.9 0 0 1-153.83-1Z"
          style={{
            fill: "#3a485b",
          }}
        />
        <path
          d="M597.53 519.59 484 404.91l-31.76 31.35 113.52 114.68a22.33 22.33 0 0 0 31.56.21 22.33 22.33 0 0 0 .21-31.56Z"
          style={{
            fill: "url(#linear-gradient-3)",
          }}
        />
      </g>
    </g>
  </svg>
);

export default SearchPartiesArt;
