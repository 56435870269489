import { useState } from "react";

import { Box, Flex } from "@chakra-ui/react";

import { Hero } from "~/components/Hero";
import { PartyOverview } from "~/components/Modals";
import { type PartyOverviewState } from "~/components/Modals/PartyOverview";

import IndexParties from "./IndexParties";
import RoiParties from "./RoiParties";

const Home = () => {
  const [selectedParty, setSelectedParty] = useState<PartyOverviewState | null>(
    null,
  );

  const viewPartyDetails = (party: PartyOverviewState) => {
    setSelectedParty(party);
  };

  return (
    <Flex
      style={{ width: "100%" }}
      flexDirection="column"
      justifyContent={"flex-start"}
      pb={12}
    >
      {!!selectedParty && (
        <PartyOverview
          onClose={() => setSelectedParty(null)}
          party={selectedParty}
        />
      )}
      <Hero viewPartyDetails={viewPartyDetails} />
      <Box mt={6} px={{ base: 2, md: 8 }}>
        <IndexParties viewPartyDetails={viewPartyDetails} />
      </Box>
      <Box mt={6} px={{ base: 2, md: 8 }}>
        <RoiParties viewPartyDetails={viewPartyDetails} />
      </Box>
    </Flex>
  );
};

export default Home;
