import {
  Box,
  Grid,
  GridItem,
  Skeleton,
  SkeletonCircle,
  VStack,
} from "@chakra-ui/react";

interface TopPerformingSkeletonPartyProps {
  rank: number;
}

export const TopPerformingSkeletonParty = ({
  rank,
}: TopPerformingSkeletonPartyProps) => {
  return (
    <Grid gap={2} cursor="pointer" alignSelf={rank === 1 ? "center" : "end"}>
      <GridItem>
        <Box
          pos="relative"
          zIndex={1}
          p={0.5}
          w="fit-content"
          mx="auto"
          borderRadius="full"
        >
          <SkeletonCircle
            w={{
              base: rank === 1 ? "6rem" : "4rem",
              sm: rank === 1 ? "10rem" : "6rem",
              md: rank === 1 ? "12rem" : "8rem",
            }}
            h={{
              base: rank === 1 ? "6rem" : "4rem",
              sm: rank === 1 ? "10rem" : "6rem",
              md: rank === 1 ? "12rem" : "8rem",
            }}
          />
        </Box>
      </GridItem>

      <GridItem textAlign="center">
        <VStack spacing={2} alignItems="center">
          <Skeleton height="8px" width="80px" />
          <Skeleton height="8px" width="40px" />
        </VStack>
      </GridItem>
    </Grid>
    // <HStack
    //   spacing={0}
    //   p={0}
    //   width={52}
    //   h={14}
    //   border={'1px solid'}
    //   borderColor={'brand.300'}
    //   borderRadius={'10'}
    //   justifyContent="space-between"
    //   alignItems="stretch"
    //   _hover={{ bg: 'brand.300', boxShadow: 'md' }}
    //   bgGradient="linear(to-b, brand.300, brand.500)"
    //   overflow={'hidden'}
    //   display={{ md: rank > 3 ? 'none' : 'flex', lg: 'flex' }}
    //   cursor="pointer"
    // >
    //   <HStack
    //     background={useColorModeValue('white', 'brand.main')}
    //     flex={1}
    //     px={2}
    //   >
    //     <SkeletonCircle size="10" />
    //     <VStack spacing={2} alignItems="start">
    //       <Skeleton height="8px" width="80px" />
    //       <Skeleton height="8px" width="40px" />
    //     </VStack>
    //   </HStack>
    //   <VStack w={10}>
    //     <Text
    //       fontWeight={'bold'}
    //       my={'auto'}
    //       h={'fit-content'}
    //       textAlign={'center'}
    //       verticalAlign={'middle'}
    //       color={'white'}
    //     >
    //       #{rank}
    //     </Text>
    //   </VStack>
    // </HStack>
  );
};
