import { useState } from "react";

import dynamic from "next/dynamic";
import NextLink from "next/link";

import {
  ChevronLeftIcon,
  ChevronRightIcon,
  TriangleDownIcon,
  TriangleUpIcon,
} from "@chakra-ui/icons";
import { Button, Flex, Heading, Icon, Text, VStack } from "@chakra-ui/react";

import { isAddressEqual } from "@partyfinance/core";
import { PartySortDirection } from "@partyfinance/thegraph-queries";

import { Button as PButton } from "~/components/Buttons";
import { Card } from "~/components/Card";
import { PartySearchLoader } from "~/components/Loaders";
import { type PartyOverviewState } from "~/components/Modals/PartyOverview";

import SearchPartiesArt from "~/assets/SearchPartiesArt";
import { usePublicPartiesByRoi } from "~/hooks/theGraph";

const PartyListItemCard = dynamic(
  () => import("~/components/Card/PartyListItemCard"),
  {
    ssr: false,
  },
);

interface IRoiParties {
  viewPartyDetails: (party: PartyOverviewState) => void;
}

const RoiParties = ({ viewPartyDetails }: IRoiParties): JSX.Element => {
  // Pagination
  const [pageIndex, setPageIndex] = useState(0);

  // Sort
  const [sortDirection, setSortDirection] = useState(PartySortDirection.Desc);

  // Fetch public parties
  const { parties, ownersNicknames, loading } = usePublicPartiesByRoi({
    sortDirection: sortDirection,
    pageIndex: pageIndex,
    pageSize: 4,
    minValue: 50,
  });

  const handleSortDirection = () => {
    setPageIndex(0);
    setSortDirection(
      sortDirection === PartySortDirection.Asc
        ? PartySortDirection.Desc
        : PartySortDirection.Asc,
    );
  };

  // Navigation handlers
  const handleNext = () => {
    setPageIndex(pageIndex + 1);
  };
  const handlePrevious = () => {
    setPageIndex(pageIndex === 0 ? 0 : pageIndex - 1);
  };

  return (
    <Flex direction="column" mx="auto" gap={6} maxW="6xl" pos="relative">
      {/* Search Input */}
      <Flex justify="space-between" alignItems="center" wrap="wrap">
        <Heading size="lg">Trading Parties</Heading>
        <Flex gap={2} alignItems="center">
          <Button
            bg={"transparent"}
            ml={"1!important"}
            w="fit-content"
            m={"0!important"}
            p={"0!important"}
            size="sm"
            _focus={{ border: "none" }}
            _hover={{ bg: "transparent" }}
            _active={{ bg: "transparent" }}
            onClick={handleSortDirection}
          >
            <Flex align="center" gap={2} mr={3}>
              <Text fontSize="xs" color="gray.500">
                ROI
              </Text>
              <VStack gap={0} fontSize="xs">
                <TriangleUpIcon
                  opacity={sortDirection === PartySortDirection.Asc ? 1 : 0.5}
                  color={"brand.500"}
                  m={0}
                  p={0}
                />
                <TriangleDownIcon
                  opacity={sortDirection === PartySortDirection.Desc ? 1 : 0.5}
                  color={"brand.500"}
                  m={0}
                  p={0}
                />
              </VStack>
            </Flex>
          </Button>
        </Flex>
      </Flex>
      {/* Party overview */}
      <Flex
        direction="row"
        gap={4}
        wrap="wrap"
        overflowX={"hidden"}
        justifyContent="center"
        alignItems="center"
      >
        {loading ? (
          [0, 1, 2, 3].map((i) => {
            return <PartySearchLoader key={i} />;
          })
        ) : parties.length === 0 ? (
          <Card textAlign="center" p={0} px={4} pt={6} pb={8}>
            <Icon as={SearchPartiesArt} fontSize="175px" mt={3} />
            <Text fontSize="18px" mb={2}>
              {pageIndex > 0 ? "No more parties found" : "No parties found"}
            </Text>
          </Card>
        ) : (
          parties.map((party) => (
            <PartyListItemCard
              key={`${party.chainId}-${party.address}`}
              party={party}
              openParty={() => viewPartyDetails(party)}
              ownerNickname={
                ownersNicknames
                  ? ownersNicknames.find((x) =>
                      isAddressEqual(x.address, party.owner),
                    )?.name ?? undefined
                  : undefined
              }
            />
          ))
        )}
      </Flex>
      {/* Pagination */}
      <Flex
        w="fit-content"
        mx="auto"
        justify="center"
        gap={2}
        flexDir={{ base: "column", sm: "row" }}
      >
        <Flex
          justifySelf={"center"}
          justifyContent="center"
          alignItems="center"
          gap={2}
        >
          <Button
            _focus={{ outline: "none" }}
            disabled={pageIndex === 0}
            onClick={handlePrevious}
            size="sm"
          >
            <ChevronLeftIcon />
          </Button>
          <Text
            color="gray.500"
            width={pageIndex < 10 ? 16 : 20}
            textAlign="center"
          >
            Page {pageIndex + 1}
          </Text>
          <Button
            _focus={{ outline: "none" }}
            /// @dev:
            /// this line is commented, becuase when fetching from financials first we can not determine if a party is closed,
            /// and therefor can not previously know if we can fetch more results
            // disabled={!canFetch}
            onClick={handleNext}
            size="sm"
          >
            <ChevronRightIcon />
          </Button>
        </Flex>
        <Flex
          h="full"
          w={{ base: "full", sm: "auto" }}
          alignSelf="center"
          alignItems="center"
        >
          <PButton
            as={NextLink}
            href="/parties/explore"
            pos={{ base: "relative", sm: "absolute" }}
            w={{ base: "full", sm: "inherit" }}
            right={0}
            alignSelf="center"
            _focus={{ outline: "none" }}
            rounded="md"
            size="sm"
          >
            Explore more
          </PButton>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default RoiParties;
