import dynamic from "next/dynamic";

import { Flex, Heading, Text } from "@chakra-ui/react";

import { api } from "~/utils/api";

import { PartySearchLoader } from "~/components/Loaders";
import { type PartyOverviewState } from "~/components/Modals/PartyOverview";

const IndexPartyCard = dynamic(
  () => import("~/components/Card/IndexPartyCard"),
  {
    ssr: false,
  },
);

interface IIndexParties {
  viewPartyDetails: (party: PartyOverviewState) => void;
}

const IndexParties = ({ viewPartyDetails }: IIndexParties): JSX.Element => {
  const { data, isLoading } = api.party.indexParties.useQuery(undefined, {
    refetchOnWindowFocus: false,
    staleTime: 60 * 1000,
    cacheTime: 15 * 60 * 1000,
  });

  return (
    <Flex direction="column" mx="auto" gap={6} maxW="6xl">
      <Heading size="lg" mx={{ base: "auto", md: "initial" }}>
        Index Parties
      </Heading>
      <Flex
        style={{ width: "100%" }}
        justifyContent={"center"}
        wrap="wrap"
        gap={4}
      >
        {isLoading ? (
          [0, 1, 2].map((i) => {
            return <PartySearchLoader key={i} />;
          })
        ) : !data || data.length === 0 ? (
          <Text>No Index Parties Found</Text>
        ) : (
          data.map((party) => (
            <IndexPartyCard
              key={`${party.chainId}-${party.address}`}
              party={party}
              openParty={() => viewPartyDetails(party)}
            />
          ))
        )}
      </Flex>
    </Flex>
  );
};

export default IndexParties;
