import {
  Avatar,
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Tag,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { GiLaurelCrown } from "react-icons/gi";

interface TopPerformingPartyProps {
  handleClick: () => void;
  img: string;
  name: string;
  roi: number;
  rank: number;
}

export const TopPerformingParty = ({
  img,
  name,
  roi,
  rank,
  handleClick,
}: TopPerformingPartyProps) => {
  // Rank Colors
  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      transition={{ duration: 0.2 }}
      style={{ flex: 1 }}
    >
      <Grid
        gap={2}
        onClick={handleClick}
        cursor="pointer"
        alignSelf={rank === 1 ? "center" : "end"}
      >
        {rank === 1 && (
          <GridItem>
            <Box mx="auto" w="fit-content" mb={-1}>
              <motion.div
                animate={{ scale: [1, 1.2, 1], rotate: [0, 5, 0] }}
                transition={{ duration: 2, repeat: Infinity }}
              >
                <GiLaurelCrown color="#f656d3" fontSize="28px" />
              </motion.div>
            </Box>
          </GridItem>
        )}
        <GridItem>
          <Box
            pos="relative"
            zIndex={1}
            p={0.5}
            w="fit-content"
            mx="auto"
            bg="#f656d3"
            borderRadius="full"
            boxShadow={`0px 0px 3px 3px #f656d3`}
          >
            <Avatar
              src={img}
              w={{
                base: rank === 1 ? "6rem" : "4rem",
                sm: rank === 1 ? "10rem" : "6rem",
                md: rank === 1 ? "12rem" : "8rem",
              }}
              h={{
                base: rank === 1 ? "6rem" : "4rem",
                sm: rank === 1 ? "10rem" : "6rem",
                md: rank === 1 ? "12rem" : "8rem",
              }}
            />
          </Box>
        </GridItem>

        <GridItem>
          <Flex
            pos="relative"
            zIndex={2}
            borderRadius="full"
            h={{ base: rank === 1 ? 6 : 4, md: rank === 1 ? 8 : 6 }}
            w={{ base: rank === 1 ? 6 : 4, md: rank === 1 ? 8 : 6 }}
            bg="#f656d3" //{rank === 1 ? gold : rank === 2 ? silver : bronze}
            justify="center"
            align="center"
            mx="auto"
            mt={{ base: rank === 1 ? -5 : -3, md: rank === 1 ? -5 : -4 }}
            boxShadow={`0 0 2px 2px #f656d3`}
          >
            <Text
              textAlign="center"
              size={{ base: "xs", md: "sm" }}
              fontSize={{ base: "xs", md: "md" }}
              fontWeight="bold"
            >
              {rank}
            </Text>
          </Flex>
        </GridItem>

        <GridItem textAlign="center">
          <Heading
            fontWeight="bold"
            // fontSize="sm"
            size={{ base: "sm", md: "md" }}
            whiteSpace={"nowrap"}
            color={useColorModeValue("gray.600", "gray.300")}
          >
            {name.slice(0, useBreakpointValue({ base: 10, md: 15 }))}
            {useBreakpointValue({
              base: name.length > 10 ? "..." : "",
              md: name.length > 15 ? "..." : "",
            })}
          </Heading>
          <Tag size="sm" mt={1}>
            <Text
              fontWeight="bold"
              fontSize="xs"
              color={roi < 0 ? "red.400" : "green.300"}
            >
              {Math.round(roi * 100) / 100}%
            </Text>
          </Tag>
        </GridItem>
      </Grid>
    </motion.div>
  );
};
