import type { GetStaticProps, NextPage } from "next";

import { createServerSideHelpers } from "@trpc/react-query/server";
import superjson from "superjson";

import { PartySortDirection } from "@partyfinance/thegraph-queries";

import { topPerformersMinValue } from "~/config";
import Layout from "~/layouts/Layout";
import Home from "~/modules/party/components/Home";
import { appRouter } from "~/server/root";
import { createInnerTRPCContext } from "~/server/trpc";

export const getStaticProps: GetStaticProps = async () => {
  const helpers = createServerSideHelpers({
    router: appRouter,
    ctx: createInnerTRPCContext({ session: null }),
    transformer: superjson,
  });
  await Promise.all([
    /// @dev same input like the IndexParties.tsx
    helpers.party.indexParties.prefetch(),
    /// @dev same input like the Hero.tsx
    helpers.party.byRoi.prefetch({
      sortDirection: PartySortDirection.Desc,
      pageIndex: 0,
      pageSize: 3,
      minValue: topPerformersMinValue,
    }),
    /// @dev same input like the RoiParties.tsx
    helpers.party.byRoi.prefetch({
      sortDirection: PartySortDirection.Desc,
      pageIndex: 0,
      pageSize: 4,
      minValue: 50,
    }),
  ]);
  return {
    props: {
      trpcState: helpers.dehydrate(),
      revalidate: 900,
    },
  };
};

const HomePage: NextPage = () => {
  return (
    <Layout title="Home | PartyFinance">
      <Home />
    </Layout>
  );
};

export default HomePage;
